/**
 * typography.scss
 * Contains the app-wide fonts and typographic styles,
 */

html {
    font-family: var(--font-family-base);
    font-kerning: normal;
    font-size: var(--font18);
}
