/**
 * theme.overrides.scss
 * Contains the theme's variables overrides
 */

html {
    /**
    * leave here the variables that you want to override, e.g.
    *
    * --hover-teal: #117E98;
    * --button-font-family: var(--font-family-medium-theme);
    * --button-primary-focus-color: var(--active-dark-blue);
    * --checkbox-icon-line-height: 18px;
    */
    --font-family-light-theme: 'InspireTWDCLight';
    --font-family-black-theme: 'InspireTWDCHeavy';
    --font-family-heavy-theme: 'InspireTWDCHeavy';
    --font-family-base-theme: 'InspireTWDC';
    --font-family-medium-theme: 'InspireTWDCMedium';
    --wdpr-multi-select-label-font-size: 12px;
    --wdpr-multi-select-label-line-height: normal;
    --wdpr-multi-select-color: rgb(0, 136, 32);
    --wdpr-multi-select-dropdown-button-color: black;
    --wdpr-multi-select-input-color: black;
}
