/**
 * mixins.scss
 * Contains the app-wide mixins
 */

@mixin font($font-size, $line-height, $font-weight:400) {
    font-size: $font-size;
    line-height: $line-height;
    font-weight: $font-weight;
}
