/**
 * functions.scss
 * Contains the app-wide functions
 */

@function zindex($layer) {
    @if not map-has-key($zindex, $layer) {
        @warn 'No z-index found for `#{$layer}`.';
    }

    @return map-get($zindex, $layer);
}
