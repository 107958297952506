/**
 * variables.scss
 * Contains the app-wide variables
 */

/* z-index variables */
$zindex: (
    'base': 1,
    'menu': 2,
    'overlay': 3,
    'modal': 4,
    'toast': 5
);
