/**
 * animations.scss
 * Contains the app-wide animations
 * e.g.
 *
 * @keyframes pulse-blue {
 *   0% {
 *     box-shadow: 0 0 0 0 rgba(colors('dark-blue'), .4);
 *   }
 *
 *   70% {
 *     box-shadow: 0 0 0 30px rgba(colors('dark-blue'), 0);
 *   }
 *
 *   100% {
 *     box-shadow: 0 0 0 0 rgba(colors('dark-blue'), 0);
 *   }
 * }
 */

@keyframes warning {
    0% {
        background: var(--bright-white);
    }

    10% {
        background: var(--sunrise-orange);
    }
}

@keyframes shake {
    10%,
    90% {
        transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
        transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
        transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
        transform: translate3d(4px, 0, 0);
    }
}
