/**
 * styles.scss
 * The entry point for the app styles. You can add global styles to this file,
 * and also import other style files
 */
@import '@wdpr/kingswell-theme/theme.scss';
@import '@wdpr/shared-device/reset.scss';
@import '@wdpr/shared-device/layout.scss';
@import './assets/styles/theme/index';
@import './assets/styles/abstracts';
@import './assets/styles/base/animations';
@import './assets/styles/base/typography';
@import './assets/styles/base/global';
@import 'primeicons/primeicons.css';

body {
    font-family: var(--font-family-base);

    /* This custom property resolves to "InspireTWDC" at runtime */
}

.app-loading-indicator-wrapper {
    opacity: 0;
    text-align: center;
    width: 100vw;
    height: 100vh;
    animation: opacity 0s;
    animation-delay: 1s;
    animation-fill-mode: forwards;
}

.app-loading-indicator {
    padding-top: 35vh;
}

@keyframes opacity {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 100%;
    }
}

// PrimeNG resets

.p-accordion-header-link:hover {
    text-decoration: none;
}

.p-accordion-header-link:visited {
    text-decoration: none;
}

.p-accordion-header-link:focus {
    text-decoration: none;
}

.p-accordion-header-link {
    text-decoration: none;
}
