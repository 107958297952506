/**
 * global.scss
 * Contains the app-wide global styles
 */

* {
    background-repeat: no-repeat;
    box-sizing: border-box;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;

    &:before,
    &:after {
        box-sizing: border-box;
    }
}

html {
    /* Prevent iOS text size adjust after orientation change, without disabling user zoom. */
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
}

body {
    min-height: 100vh;
    overflow-x: hidden;
}

a {
    cursor: pointer;
}
